import React from 'react';

export default function Section0() {
    return (
        <>
            <div className="container bg-white rounded-5">
                <h2 className='text-center py-5' style={{color: "#b30000"}}>سطحة هيدروليك جدة 0559760612</h2>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img3.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة جدة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img5.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة جدة رقم سطحات جدة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img6.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة جدة ارخص الأسعار</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img2.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة من جدة الى جازان أبها خميس مشيط 0559760612</figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة جدة رقم سطحات جدة</p>
                        <p className='fs-5'>سطحه حي ابحر الجنوبية– سطحه حي ابحر الشمالية– سطحه حي المحمدية– سطحه حي المرجان– سطحه حي البساتين– سطحه حي النزهة– سطحه حي النعيم– سطحه حي البوادي– سطحه حي الخالدية– سطحه حي الفيصلية– سطحه حي السامر– سطحه حي الحمدانية– سطحه حي الربوة– سطحه حي المروة– سطحه حي السلامة– سطحه حي الاندلس– سطحه حي الحمراء– سطحه حي الروضة– سطحه حي الزهراء– سطحه حي الكورنيش الشمالي– سطحه حي بريمان– سطحه حي الصفا– سطحه حي النهضة</p>
                    </div>
                </div>

                <div className= "row text-center pb-5">
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>رقم سطحات</p>
                        <p className='fs-5'>"أرقام سطحات سحب سيارات متاحة على مدار 24 ساعة من أجل خدمتكم والعمل على مساعدتكم في أي وقت وأي مكان، تقدم شركة سطحة الطائف أفضل خدمات سطحة سحب سيارات سطحات نقل سيارات، كما تضمن لكم أعلى مستوى من الجودة من أجل تحقيق الأفضل لكم، يمكنكم الإتصال على رقم سطحة لكم، يمكنكم الإتصال على رقم سطحة جدة</p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img4.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>رقم سطحات 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/pic8.jpg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة جدة رقم سطحات جدة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </>
    )
}