import React from 'react';

export default function Section9() {
    return (
        <>
            <div className="container bg-white rounded-5">
                <h2 className='text-center py-5' style={{color: "#b30000"}}>سطحه مكه المكرمه 0559760612</h2>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img1.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحه مكةالمكرمة رقم جوال 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img2.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة هيدروليك مكة المكرمة 0559760612</figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة هيدروليك مكة المكرمة</p>
                        <p className='fs-5'>رقم جوال 0559760612</p>
                        <p className='fs-5'>سطحة بمكة المكرمة بارخص الاسعار ونش انقاذب  مكة رقم ونشات بمكة نقل السيارات المصدومة والمعطلة رقم سطحة هيدروليك بمكةسطحة عادي</p>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img3.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحه مكة رقم سطحات مكة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img4.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة مكة المكرمة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center pb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/pic12.jpg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة مكة 0559760612</figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة مكة المكرمة</p>
                        <p className='fs-5'>سطحة مكة الي اي منطقه ارخص الأسعار</p>
                        <p className='fs-5'>نقل سيارات دخل وخارج الرياض دمام جدة الباحة الطائف أبها نجران جيزان سكاكا الجوف مدينة منوره نقل سيارات ارخص الأسعار</p>
                    </div>
                </div>
            </div>
        </>
    )
}