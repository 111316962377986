import React from 'react';

export default function Section3() {
    return (
        <>
            <div className="container bg-white rounded-5">
                <h2 className='text-center py-5' style={{color: "#b30000"}}>0559760612 سطحة المخوة رقم سطحات</h2>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>0559760612 سطحة المخوة دخل وخارج أرخص الأسعار</p>
                        <p className='fs-5'>سطحة المخوة هيدروليك و عادي</p>
                        <p className='fs-5'>نقل سيارات من المخوة الى اي منطقه ارخص الاسعار</p>
                        <p className='fs-5'>نقل سيارات من المخوة الى الرياض دمام جدة الباحة أبها خميس مشيط المدينة المنورة تبوك نجران اي منطقه</p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className='img-fluid' src="/images/img8.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>0559760612 احمد سطحه باكستانى</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                        <img className='img-fluid' src="/images/img9.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>رقم سطحات سطحة المخوة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                        <img className="img-fluid" src="/images/img10.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحه المخوة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                        <img className="img-fluid" src="/images/img11.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>رقم سطحات المخوة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                        <img className="img-fluid" src="/images/img12.jpeg" style={{width: "30vw"}} alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحه المخوة رقم جوال 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                        <img className="img-fluid" src="/images/pic10.jpg" style={{width: "30vw"}} alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة المخوة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </>
    )
}