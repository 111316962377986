import './App.css';
import Nav from "./components/Nav.js";

function App() {
  return (
    <>
    <Nav />
    </>
  );
}

export default App;
