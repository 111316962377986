import React from 'react';

export default function Section5() {
    return (
        <>
            <div className="container bg-white rounded-5">
                <h2 className='text-center py-5' style={{color: "#b30000"}}>سطحة الباحة رقم سطحات الباحة 0559760612</h2>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img4.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحه الباحة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img5.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحه الباحة باكستاني 0559760612</figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحه الباحة باكستاني</p>
                        <p className='fs-5'>نقل سيارات الرياض دمام جدة مكة المكرمة نجران أبها تبوك عرعر سكاكا الظهران</p>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة الباحة رقم سطحات الباحة</p>
                        <p className='fs-5'>نقل سيارات ارخص الأسعار من الباحة إلى أي منطقة الرياض دمام مكة المكرمة نجران أبها تبوك عرعر سكاكا الظهران اي منطقه</p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img6.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center pb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img7.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة الباحة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img8.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة من الباحة الى جدة نقل سيارات سطحة من جدة الباحة 0559760612</figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة الباحة نقل سيارات من الباحة الى جدة مكة المكرمة الطائف الرياض دمام اي منطقه 0559760612</p>
                        <p className='fs-5'>نقل سيارات دخل وخارج أرخص الأسعار</p>
                        <p className='fs-5'>نقل من الباحة إلى أي منطقة</p>
                        <p className='fs-5'>الرياض دمام جبيل الحسا جدة مكة المكرمة الطائف المدينة المنورة جازان خميس مشيط أبها اي منطقه</p>
                        <p className='fs-5'>سطحة هيدروليك و عادي</p>
                        <p className='fs-5'>رقم جوال 0559760612 سايق باكستاني</p>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img9.jpeg" style={{width: "50vw"}} alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحه الباحة الى جدة نقل سيارات من جدة الى الباحة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img10.jpeg" style={{width: "50vw"}} alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحه الباحة 0559760612 عرفان باكستاني</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center pb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/pic1.png" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة الباحة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </>
    )
}