import React, { useState } from 'react';
import Section0 from './Section0';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import Section7 from './Section7';
import Section8 from './Section8';
import Section9 from './Section9';
import Section10 from './Section10';
import Section11 from './Section11';


export default function Nav() {
    const [activeSection, setActiveSection] = useState('section0');
    const [menuOpen, setMenuOpen] = useState(false);

    const handleNavClick = (e, section) => {
        e.preventDefault();
        setActiveSection(section);
        setMenuOpen(false);
        document.getElementById('top').scrollIntoView({ behavior: 'smooth' });
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <div id="top" className="container my-5 rounded-5" style={{ backgroundColor: "#b30000" }}>
                <div className="row py-5">
                    <h2 className='text-center text-white p-3 rounded-5' style={{ backgroundColor: "#800000" }}>
                        0559760612 خدمة النقل في المملكة العربية السعودية
                    </h2>
                    <span className='text-center text-white p-3 fs-5 fw-semibold'>سطحة جدة نقل سيارات 0559760612</span>
                </div>

                <div className="row mt-md-5">
                    <div className="col-12 d-md-none">
                        <button className="menu-button" onClick={toggleMenu}>
                            {menuOpen ? 'Close Menu' : 'Open Menu'}
                        </button>
                    </div>
                    <div className={`col-md-3 col-sm-12 ${menuOpen ? 'd-block' : 'd-none d-md-block'}`}>
                        <ul className="nav flex-column">
                            <li className={`nav-item m-1 ${activeSection === 'section0' ? 'active' : ''}`}>
                                <a className="nav-link fw-semibold" href="/" onClick={(e) => handleNavClick(e, 'section0')}>0559760612 سطحه جدة</a>
                            </li>
                            <li className={`nav-item m-1 ${activeSection === 'section1' ? 'active' : ''}`}>
                                <a className="nav-link fw-semibold" href="/" onClick={(e) => handleNavClick(e, 'section1')}>0559760612 سطحه القنفذة</a>
                            </li>
                            <li className={`nav-item m-1 ${activeSection === 'section2' ? 'active' : ''}`}>
                                <a className="nav-link fw-semibold" href="/" onClick={(e) => handleNavClick(e, 'section2')}>0559760612 سطحه القوز</a>
                            </li>
                            <li className={`nav-item m-1 ${activeSection === 'section3' ? 'active' : ''}`}>
                                <a className="nav-link fw-semibold" href="/" onClick={(e) => handleNavClick(e, 'section3')}>0559760612 سطحه جازان</a>
                            </li>
                            <li className={`nav-item m-1 ${activeSection === 'section4' ? 'active' : ''}`}>
                                <a className="nav-link fw-semibold" href="/" onClick={(e) => handleNavClick(e, 'section4')}>سطحه أبها خميس مشيط 0559760612</a>
                            </li>
                            <li className={`nav-item m-1 ${activeSection === 'section5' ? 'active' : ''}`}>
                                <a className="nav-link fw-semibold" href="/" onClick={(e) => handleNavClick(e, 'section5')}>سطحه المخوة 0559760612</a>
                            </li>
                            <li className={`nav-item m-1 ${activeSection === 'section6' ? 'active' : ''}`}>
                                <a className="nav-link fw-semibold" href="/" onClick={(e) => handleNavClick(e, 'section6')}>سطحه المظيلف 0559760612</a>
                            </li>
                            <li className={`nav-item m-1 ${activeSection === 'section7' ? 'active' : ''}`}>
                                <a className="nav-link fw-semibold" href="/" onClick={(e) => handleNavClick(e, 'section7')}>سطحه الباحه 0559760612</a>
                            </li>
                            <li className={`nav-item m-1 ${activeSection === 'section8' ? 'active' : ''}`}>
                                <a className="nav-link fw-semibold" href="/" onClick={(e) => handleNavClick(e, 'section8')}>سطحه محايل عسير 0559760612</a>
                            </li>
                            <li className={`nav-item m-1 ${activeSection === 'section9' ? 'active' : ''}`}>
                                <a className="nav-link fw-semibold" href="/" onClick={(e) => handleNavClick(e, 'section9')}>سطحه رياض دمام 0559760612</a>
                            </li>
                            <li className={`nav-item m-1 ${activeSection === 'section10' ? 'active' : ''}`}>
                                <a className="nav-link fw-semibold" href="/" onClick={(e) => handleNavClick(e, 'section10')}>سطحه درب 0559760612</a>
                            </li>
                            <li className={`nav-item m-1 ${activeSection === 'section11' ? 'active' : ''}`}>
                                <a className="nav-link fw-semibold" href="/" onClick={(e) => handleNavClick(e, 'section11')}>سطحه مكه المكرمه 0559760612</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-9 col-sm-12">
                        {activeSection === 'section0' && <Section0 />}
                        {activeSection === 'section1' && <Section1 />}
                        {activeSection === 'section2' && <Section2 />}
                        {activeSection === 'section3' && <Section3 />}
                        {activeSection === 'section4' && <Section4 />}
                        {activeSection === 'section5' && <Section5 />}
                        {activeSection === 'section6' && <Section6 />}
                        {activeSection === 'section7' && <Section7 />}
                        {activeSection === 'section8' && <Section8 />}
                        {activeSection === 'section9' && <Section9 />}
                        {activeSection === 'section10' && <Section10 />}
                        {activeSection === 'section11' && <Section11 />}
                    </div>
                </div>

                <div className="row mt-5 pb-3">
                    <p className='text-center fw-bold fs-5 rounded-5 p-2' style={{ color: "#800000", backgroundColor: "white" }}>احمد سطحه 0559760612<br />نقل سيارات دخل وخارج سطحه جدة، سطحه القنفذة، سطحه القوز، سطحه جازان، سطحه أبها خميس مشيط، سطحه المخوة، سطحه المظيلف، سطحه الباحه، سطحه محايل عسير، سطحه رياض دمام، سطحه درب، سطحة مكه المكرمه</p>
                </div>
            </div>
        </>
    );
}
