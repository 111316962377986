import React from 'react';

export default function Section6() {
    return (
        <>
            <div className="container bg-white rounded-5">
                <h2 className='text-center py-5' style={{color: "#b30000"}}>0559760612 سطحة محايل عسير رقم سطحات</h2>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>0559760612 سطحة محايل عسير دخل وخارج أرخص الأسعار</p>
                        <p className='fs-5'>سطحة محايل عسير هيدروليك و عادي</p>
                        <p className='fs-5'>نقل سيارات من محايل عسير الى اي منطقه ارخص الاسعار</p>
                        <p className='fs-5'>نقل سيارات من محايل عسير الى الرياض دمام جدة الباحة أبها خميس مشيط المدينة المنورة تبوك نجران اي منطقه</p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className='img-fluid' src="/images/img11.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>0559760612 احمد سطحه باكستانى</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                        <img className='img-fluid' src="/images/img12.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>رقم سطحات سطحة محايل عسير 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                        <img className="img-fluid" src="/images/img13.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحه محايل عسير 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                        <img className="img-fluid" src="/images/img14.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>رقم سطحات محايل عسير 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                        <img className="img-fluid" src="/images/img1.jpeg" style={{width: "30vw"}} alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحه محايل عسير رقم جوال 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                        <img className="img-fluid" src="/images/pic2.png" style={{width: "30vw"}} alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة محايل عسير 0559760612</figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </>
    )
}