import React from 'react';

export default function Section2() {
    return (
        <>
            <div className="container bg-white rounded-5">
                <h2 className='text-center py-5' style={{color: "#b30000"}}>سطحة من الرياض الي ابها خميس مشيط سطحة ابها الي الرياض</h2>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة من الرياض الي أبها 0559760612</p>
                        <p className='fs-5'>نقل سيارات دخل وخارج الرياض</p>
                        <p className='fs-5'>0559760612</p>
                        <p className='fs-5'>جدة. مكة المكرمة  الطائف  الباحة  المدينة المنورة  جازان  خميس مشيط  أبها</p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img1.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة من الرياض الى أبها خميس مشيط سطحة نقل سيارات من ابها إلى الرياض 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img2.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة الرياض 0559760612</figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة الرياض نقل سيارات ارخص الأسعار 0559760612</p>
                        <p className='fs-5'>رقم سطحات الرياض</p>
                        <p className='fs-5'>جدة مكة المكرمة الطائف الباحة المدينة المنورة جازان خميس مشيط أبها اي منطقه</p>
                        <p className='fs-5'>0559760612</p>
                    </div>
                </div>
                
                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img3.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة الرياض أبها خميس مشيط نقل سيارات دخل وخارج أرخص الأسعار 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img4.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحه الرياض 0559760612</figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة الرياض رقم سطحات الرياض</p>
                        <p className='fs-5'>نقل سيارات</p>
                        <p className='fs-5'>داخل وخارج أرخص الأسعار</p>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة الرياض رقم سطحات الرياض</p>
                        <p className='fs-5'>نقل سيارات ارخص الأسعار</p>
                        <p className='fs-5'>0559760612</p>
                        <p className='fs-5'>نقل من الرياض الى جدة 1000ريال</p>
                        <p className='fs-5'>نقل من الرياض إلى الطائف 800 ريال</p>
                        <p className='fs-5'>نقل من الرياض إلى أبها 1200 ريال</p>
                        <p className='fs-5'>نقل من الرياض إلى الباحة 1200 ريال</p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img5.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة من الرياض الي ابها خميس نقل سيارات من ابها خميس الي الرياض 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img6.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة من الرياض الى جدة</figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة الرياض رقم سطحات الرياض</p>
                        <p className='fs-5'>نقل سيارات دخل وخارج الرياض</p>
                        <p className='fs-5'>رقم سطحة الرياض 0559760612</p>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img7.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة الرياض 0559760612</figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة من الرياض الى جدة ارخص الأسعار</p>
                        <p className='fs-5'>نقل سيارات دخل وخارج الرياض</p>
                        <p className='fs-5'>جدة مكة المكرمة الطائف الباحة المدينة المنورة جازان خميس مشيط أبها اي منطقه</p>
                    </div>
                </div>

                <div className= "row text-center pb-5">
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحات من الرياض الي ابها خميس نقل سيارات من ابها الي الرياض بأرخص الأسعار</p>
                        <p className='fs-5'>سطحة الرياض رقم سطحات الرياض</p>
                        <p className='fs-5'>نقل سيارات دخل وخارج أرخص الأسعار الرياض إلى أي منطقة دمام الحسا جبيل جدة مكة المكرمة الطائف الباحة المدينة المنورة جازان خميس مشيط أبها اي منطقه</p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/pic7.png" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحه الرياض</figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </>
    )
}