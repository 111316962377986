import React from 'react';

export default function Section7() {
    return (
        <>
            <div className="container bg-white rounded-5">
                <h2 className='text-center py-5' style={{color: "#b30000"}}>سطحة من جدة الى جازان نقل سطحة جازان الى جدة 0559760612</h2>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة الدمام هيدروليك و عادي نقل سيارات دخل وخارج أرخص من الدمام الى جدة مكة المكرمة الطائف الباحة المدينة المنورة 0559760612</p>
                        <p className='fs-5'>سطحة في الدمام نوفر لك خدمة نقل السيارات المعطلة أو المصدومة يتوفر سطحات هيدروليك *عادي او فل داون او مغلقه* وسطحه ونش لنقل السيارات لسنا فقط متخصصون في نقل السيارات المعطلة من المكان الذى حدث به العطل لأى مكان آخر لتصليحها ، بل أيضاً لدينا سطحات متخصصة في نقل السيارات الأنيقة والفخمة والرياضيه</p>
                        <p className='fs-5'>0559760612</p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img2.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة هيدروليك دمام 0559760612</figcaption>
                        </figure>
                    </div>
                </div>


                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img3.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة الدمام نقل سيارات دخل وخارج أرخص الأسعار</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img4.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة الدمام هيدروليك و عادي 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img5.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>هيدروليك سطحة الدمام</figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة هيدروليك الدمام 0559760612</p>
                        <p className='fs-5'>سطحة الدمام نقل سيارات ارخص الأسعار</p>
                        <p className='fs-5'>نقل من الدمام الى الرياض جدة مكة الطائف الباحة المدينة المنورة جازان خميس مشيط أبها اي منطقه</p>
                    </div>
                </div>

                <div className= "row text-center pb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img6.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة هيدروليك الدمام 0559760612</figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة هيدروليك الدمام</p>
                        <p className='fs-5'>سطحة الدمام هيدروليك و عادي</p>
                        <p className='fs-5'>نقل سيارات دخل وخارج أرخص الأسعار</p>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة هيدروليك جدة إلى الرياض من الرياض الى جدة 0559760612</p>
                        <p className='fs-5'>سطحه من الرياض الى جدة بأرخص</p>
                        <p className='fs-5'>سطحة من جدة الي الرياض شحن السيارات من جده اؤ الرياض الى ائ منطقة السعودية</p>
                        <p className='fs-5'>سعر شحن سيارة سطحه من جدة الى الطائف: سعر شحن سيارة سطحه من جدة الى الدمام</p>
                        <p className='fs-5'>سعر شحن سيارة سطحه من جدة الى بيشة: سعر شحن سيارة سطحه من جدة الى الباحة</p>
                        <p className='fs-5'>سعر شحن سيارة سطحه من جدة الى عرعر: سعر شحن سيارة سطحه من الرياض الى القريات</p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img7.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة نقل سيارات دخل وخارج الرياض جدة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img8.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة هيدروليك رقم سطحات الرياض جدة 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة جدة إلى الرياض</p>
                        <p className='fs-5'>سطحة جدة إلى الرياض نقل سيارات ارخص الأسعار من الرياض الى جدة</p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img9.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة جدة إلى الرياض 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center pb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/pic6.png" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة هيدروليك جدة إلى الرياض 0559760612</figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة جدة إلى الرياض من الرياض الى جدة ارخص الأسعار</p>
                        <p className='fs-5'>سطحة هيدروليك جدة إلى الرياض 0559760612</p>
                        <p className='fs-5'>سطحة من جدة إلى الرياض شحن السيارات من جده اؤ الرياض الى ائ منطقة السعودية هيدروليك وعادي سطحة من الرياض الي جدة الدمام.سعر شحن سيارة سطحه من جدة الى الطائف: سعر شحن سيارة سطحه من جدة الى الدمامسعر شحن سيارة سطحه من جدة الى بيشة: سعر شحن سيارة سطحه من جدة الى الباحةسعر شحن سيارة سطحه من جدة الى عرعر: سعر شحن سيارة سطحه من جدة الى القرياتسعر شحن سيارة سطحه من جدة الى تبوك: سعر شحن سيارة سطحه من جدة الى المدينة</p>
                    </div>
                </div>
            </div>
        </>
    )
}