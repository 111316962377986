import React from 'react';

export default function Section1() {
    return (
        <>
            <div className="container bg-white rounded-5">
                <h2 className='text-center py-5' style={{color: "#b30000"}}>سطحه جازان رقم سطحات جازان 0559760612</h2>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img1.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}></figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة جازان هيدروليك</p>
                        <p className='fs-5'>سطحة جازان نقل سيارات دخل وخارج</p>
                        <p className='fs-5'>نقل من جازان إلى أي منطقة الرياض دمام مكة المكرمة نجران أبها تبوك عرعر سكاكا الظهران اي منطقه ارخص الأسعار</p>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحة جازان رقم سطحات جازان 0559760612</p>
                        <p className='fs-5'>رقم سطحات نقل سيارات دخل وخارج</p>
                        <p className='fs-5'>نقل من جازان إلى أي منطقة أرخص الأسعار</p>
                        <p className='fs-5'>جدة مكة المكرمة الطائف الباحة المدينة المنورة جازان خميس مشيط أبها الرياض دمام</p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/img8.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>رقم جوال 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img7.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة جيزان رقم سطحات جيزان 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center mb-5">
                    <div className="col-12">
                        <figure>
                            <img className="img-fluid" src="/images/img14.jpeg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحة جازان رقم سطحات جازان 0559760612</figcaption>
                        </figure>
                    </div>
                </div>

                <div className= "row text-center pb-5">
                    <div className="col-md-6 col-sm-12">
                        <figure>
                            <img className="img-fluid" src="/images/pic9.jpg" alt="" />
                            <figcaption className='fw-semibold fs-5' style={{color: "#b30000"}}>سطحه جازان 0559760612</figcaption>
                        </figure>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className='fs-5'>سطحه جازان 0559760612</p>
                        <p className='fs-5'>رقم سطحات جيزان 0559760612</p>
                    </div>
                </div>
            </div>
        </>
    )
}